import { ModuleType, SurveyConfigModule } from "./fanApp";
import { PreSaveReleaseModuleData } from "./modules";

export const findPreSaveSurveyConfigModule = (
  modules: SurveyConfigModule[] = [],
): SurveyConfigModule<PreSaveReleaseModuleData> | undefined => {
  for (let index = 0; index < modules.length; index++) {
    const mod = modules[index];

    const found =
      mod.type === "PreSaveRelease"
        ? mod
        : findPreSaveSurveyConfigModule(mod.modules);

    if (found) {
      return found as SurveyConfigModule<PreSaveReleaseModuleData>;
    }
  }
};

export const findSurveyConfigModule = <D>(
  modules: SurveyConfigModule[] = [],
  type: ModuleType,
): SurveyConfigModule<D> | undefined => {
  for (let index = 0; index < modules.length; index++) {
    const mod = modules[index];

    const found =
      mod.type === type ? mod : findSurveyConfigModule(mod.modules, type);

    if (found) {
      return found as SurveyConfigModule<D>;
    }
  }
};
