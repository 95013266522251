import { Timestamp } from "./compat";
import { Coordinates, CountryCode } from "./location";
import { VirtualOptIns } from "./creator";

export enum UserEventStates {
  draft,
  submitted,
  registered,
}

export type Meta = {
  country?: CountryCode;
};

export type Header<T = Timestamp> = {
  artistName: string;
  displayedName?: string;
  image: string;
  date: T; //todo:get Luxon integrated
  venue?: string;
  location?: string;
};

export interface BaseField {
  id: string;
  label?: string;
  required?: boolean;
}

export interface SelectField extends BaseField {
  type: "select";
  options: { label: string; value: string }[];
  placeholder?: string;
}

export interface ImageSelectField extends BaseField {
  type: "image-select";
  options: { label: string; value: string; image: string }[];
}

export interface CheckboxField extends BaseField {
  type: "checkbox";
  description?: string;
}

export interface TextField extends BaseField {
  type: "text";
  placeholder?: string;
}

export interface GoogleMapsAutocompleteField extends BaseField {
  type: "google-maps-autocomplete";
  placeholder?: string;
}

export type DateFieldVariant = "dob";

export interface DateField extends BaseField {
  type: "date";
  placeholder?: string;
  requiredAge?: number;
  variant?: DateFieldVariant;
}

export type FormField =
  | SelectField
  | ImageSelectField
  | CheckboxField
  | TextField
  | DateField
  | GoogleMapsAutocompleteField;

export type RegisterPhoneValidation = "required" | "optional" | "no-phone";

export type Register = {
  title?: string;
  subtitle?: string;
  terms?: string;
  sms?: string;
  customCheckbox?: string;
  ctalabel?: string;
  optionalPhone?: boolean;
  optionalSms?: boolean;
  customTerms?: string;
  customAgreement?: string;
  hasSweepstakes?: boolean;
  customSweepstakesRules?: string;
  customFields?: FormField[];
  /** Optional disclaimers to display on the registration form, supports HTML */
  disclaimers?: string[];
  fieldsOrder?: { id: string; index: number }[];
  phoneValidation?: RegisterPhoneValidation;
};

export type VisibilityRuleState = {
  states: UserEventStates[];
  hide?: boolean;
};

export type VisibilityRuleTime = {
  time: Date;
  hide?: boolean;
};

export type VisibilityRules = VisibilityRuleState | VisibilityRuleTime;

export const getVisibilityRuleStates = (rules: VisibilityRules) => {
  if (Array.isArray((rules as VisibilityRuleState).states)) {
    return (rules as VisibilityRuleState).states;
  }
  return [];
};

export type CardType = {
  visible?: VisibilityRules;
  headline?: string;
  subtitle?: string;
  icon?: "checkmark" | "shirt" | "donate" | "user" | "checklist" | "fizz";
  customIcon?: string;
  donate?: string;
  body?: string;
  cta?: string;
  ctalabel?: string;
  background?: string;
  color?: string;
  image?: string;
  merch?: Merch[];
  html?: string;
  script?: string;
  tab?: string;
  songs?: EncoreSong[];

  /**
   * Use by analytics to display the name of the card for reporting purposes
   */
  label?: {
    auto: string;
    custom?: string;
  };
};

export type EncoreSong = {
  id: string;
  title: string;
  album?: string;
};

export type Merch = {
  name?: string;
  url?: string;
  photo?: string;
};

export type SocialCard = {
  title?: string;
  subtitle?: string;
  spotify?: string;
  apple?: string;
  amazon?: string;
  deezer?: string;
  youtube?: string;
  pandora?: string;
  tidal?: string;
  iHeartRadio?: string;
};

export const SocialLinkNames = [
  "facebook",
  "instagram",
  "tiktok",
  "spotify",
  "youtube",
  "twitter",
  "shazam",
] as const;
export type SocialLinkName = (typeof SocialLinkNames)[number];

/**
 * Maps a social sevice (by key name) to a URL
 */
export type SocialLinks<URL = string> = {
  [K in SocialLinkName]?: URL;
};

export type Colors = {
  dark?: string;
  light?: string;
};

export enum EventTypes {
  Swiper,
  Round,
  Cards,
  Boxy,
  Grunge,
}

export type EventConfig<T = Timestamp> = {
  type: EventTypes;
  header: Header<T>;
  colors?: Colors;
  register?: Register;
  social?: SocialCard;
  socialLinks?: SocialLinks;
  cards?: CardType[];
  footer?: string;
  meta?: Meta;
  endedAt?: T | null;
  customPostShowEmail?: { from?: string };
};

export const EventStatuses = [
  "published",
  "unpublished",
  "deleted",
  "demo",
  "draft",
] as const;
export type EventStatus = (typeof EventStatuses)[number];

export type MaxCampaign = {
  id: number;
  name: string;
  imageUrl?: string;
};

export type EventConfigRoot<T = Timestamp> = {
  groupId: string;
  memberUids: string[];
  languages: string[];
  artistName: string;
  image: string;
  venue: string;
  address?: string;
  coordinates: Coordinates;
  startsAt: T;
  timeZoneName?: string;
  endedAt: T | null;
  deletedAt: T | null;
  autoEndAfter: number;
  status: EventStatus;
  emailTemplateId?: string;
  maxCampaign?: MaxCampaign;
  geocode?: google.maps.GeocoderResult;
  additionalGroups?: string[];
  virtualOptIns?: VirtualOptIns;
};
