import * as React from "react";
import { SVGProps } from "react";
export const SvgSpotify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7322881,9.09217487 C12.1535412,7.56072746 7.89995582,7.41991569 5.43823364,8.16705874 C5.04283343,8.28704489 4.62479252,8.06388594 4.50499743,7.66858125 C4.38520235,7.27298998 4.60817024,6.85523566 5.00376151,6.73505845 C7.82964546,5.87729124 12.5273515,6.0429409 15.4960534,7.80518969 C15.8516174,8.01631181 15.96826,8.47552631 15.75752,8.83051718 C15.546589,9.18608122 15.0869923,9.30329699 14.7322881,9.09217487 Z M14.6478392,11.360448 C14.4669047,11.6540129 14.0830637,11.7461042 13.7898809,11.5658383 C11.6399699,10.2443667 8.36165411,9.86148096 5.81815794,10.6335574 C5.48829156,10.7331956 5.13998782,10.5472935 5.03977646,10.2180003 C4.94042487,9.88822946 5.12642251,9.54049891 5.4557157,9.44019202 C8.36127199,8.55844668 11.9733709,8.98546744 14.44264,10.5029674 C14.7358227,10.6835198 14.828105,11.0675519 14.6478392,11.360448 Z M13.6689395,13.5388271 C13.5251663,13.7745961 13.2182272,13.8484411 12.983318,13.7047634 C11.1047132,12.5565838 8.74014544,12.2973144 5.95543508,12.9333556 C5.68709026,12.9948772 5.41960522,12.8267437 5.35837025,12.5583989 C5.29694422,12.2901496 5.4645045,12.0226646 5.7334225,11.9614296 C8.78084138,11.2648221 11.394839,11.5645964 13.5034809,12.8531101 C13.7385811,12.9966923 13.8126172,13.3038224 13.6689395,13.5388271 Z M10,2 C5.58181579,2 2,5.58172026 2,9.99990447 C2,14.4185663 5.58181579,18 10,18 C14.4183753,18 18,14.4185663 18,9.99990447 C18,5.58172026 14.4183753,2 10,2 L10,2 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
