export const ErrorCodes = ['unknown-error', 'permission-denied', 'failed-precondition'] as const;
export type ErrorCode = (typeof ErrorCodes)[number];

export interface ApiResponseError {
	success: false;
	error: ErrorCode;
	message?: string;
	data?: undefined;
}

export interface ApiResponseSuccess<T> {
	success: true;
	data: T;
}

export type ApiResponse<T> = ApiResponseError | ApiResponseSuccess<T>;

//export type RequestResponse<Req, Res> = { request: Req; response: Res | ApiResponseError };
