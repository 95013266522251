import {
  useRule,
  Container,
  Text,
  TextQuestion,
  ImageQuestion,
  MultipleSelectQuestion,
  SingleSelectQuestion,
  YearBorn,
  OfAge,
  NameQuestion,
  EmailQuestion,
  PhoneQuestion,
  SubmitButton,
  Confirm,
  ZipQuestion,
  Progress,
  SocialMedia,
  Profiling,
  AutocompleteQuestion,
} from "Components";
import { Card } from "./Card";
import { Page } from "./Page";
import { DropdownQuestion } from "./Dropdown";
import { RankedQuestion } from "./RankedQuestion";
import {
  PreSaveReleaseModuleData,
  SurveyConfigModule,
} from "@max/common/setfan";
import { HtmlModule } from "./HtmlModule";
import { SongsQuestion } from "./SongsQuestion";
import { ActionRequiredModule } from "./ActionRequired";
import { CountryQuestion } from "./CountryQuestion";
import { PreSaveRelease } from "./PreSaveRelease";
import { SpotifyPlaylist } from "./SpotifyPlaylist";
import { SpotifyPlaylistData } from "@max/common/src/setfan/modules/SpotifyPlaylist";
import { Toaster } from "Components/Toaster";

export const Module = ({ visibility, ...props }: SurveyConfigModule) => {
  const visible = useRule(visibility);
  if (!visible) {
    return null;
  }
  if (props.actionRequired) {
    return <ActionRequiredModule type={props.type} />;
  }
  switch (props.type) {
    case "container":
      return <Container {...props} />;
    case "page":
      return <Page {...props} />;
    case "card":
      return <Card {...props} />;
    case "image":
      return <img {...props} alt="artist survey" />;
    case "text":
      return <Text {...props} />;
    case "TextQuestion":
      return <TextQuestion {...props} />;
    case "Html":
      return <HtmlModule {...props} />;
    case "ImageQuestion":
      return <ImageQuestion {...props} />;
    case "MultipleSelectQuestion":
      return <MultipleSelectQuestion {...props} />;
    case "SingleSelectQuestion":
      return <SingleSelectQuestion {...props} />;
    case "YearBorn":
      return <YearBorn {...props} />;
    case "OfAge":
      return <OfAge {...props} />;
    case "NameQuestion":
      return <NameQuestion {...props} />;
    case "EmailQuestion":
      return <EmailQuestion {...props} />;
    case "PhoneQuestion":
      return <PhoneQuestion {...props} />;
    case "ZipQuestion":
      return <ZipQuestion {...props} />;
    case "confirm":
      return <Confirm {...props} />;
    case "SongsQuestion":
      return <SongsQuestion {...props} />;
    case "submit":
      return <SubmitButton {...props} />;
    case "progress":
      return <Progress {...props} />;
    case "socialmedia":
      return <SocialMedia {...props} />;
    case "profiling":
      return <Profiling {...props} />;
    case "Dropdown":
      return <DropdownQuestion {...props} />;
    case "RankedQuestion":
      return <RankedQuestion {...props} />;
    case "Autocomplete":
    case "TypeaheadQuestion":
      return <AutocompleteQuestion {...props} />;
    case "CountryQuestion":
      return <CountryQuestion {...props} />;
    case "PreSaveRelease":
      return (
        <PreSaveRelease
          {...(props as SurveyConfigModule<PreSaveReleaseModuleData>)}
        />
      );
    case "SpotifyPlaylist":
      return (
        <>
          <Toaster />
          <SpotifyPlaylist
            {...(props as SurveyConfigModule<SpotifyPlaylistData>)}
          />
        </>
      );
    default:
      return null;
  }
};
