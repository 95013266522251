import { DateTime } from "luxon";
import {
  BuilderConfigDocument,
  Concert,
  Release,
  SelectedTemplateColors,
  TranslatableContent,
  LanguageCode,
} from ".";
import { Timestamp } from "../compat";
import {
  EventTypes,
  CardType,
  EventConfig as EventConfigBase,
  EventConfigRoot,
  Register,
  UserEventStates,
  getVisibilityRuleStates,
} from "../event";
import { GOOGLE_PLACES_ESTABLISHMENT_TYPES } from "../location";
import { enumKeys } from "../utils";

type OptIn = {
  id?: string;
  url: string;
  entityName: string;
};

type OptIns = {
  artist?: string;
  entities?: OptIn[];
};

type UpdatedRegister = Register & {
  optIns?: OptIns;
};

interface EventConfig extends Omit<EventConfigBase<Timestamp>, "register"> {
  register?: UpdatedRegister;
}

export interface Event {
  config: EventConfig;
  root: Partial<EventConfigRoot>;
}

const luxify = (t: Timestamp) =>
  typeof t.toDate === "function"
    ? DateTime.fromJSDate(t.toDate())
    : DateTime.utc().minus({ year: 100 });

export const getAddressComponent = (
  types: string | string[],
  components?: google.maps.GeocoderAddressComponent[],
) => {
  let result = "";

  if (typeof types === "string") {
    types = [types];
  }

  for (const type of types) {
    if (!result) {
      result =
        components?.find(({ types }) => types.includes(type))?.short_name || "";
    }
  }
  return result;
};

const getPreRegCard = (cards?: CardType[]) => {
  return cards?.find((c) => {
    if (c.visible && !c.visible.hide) {
      const states = getVisibilityRuleStates(c.visible);
      if (states.includes(UserEventStates.draft)) {
        return true;
      }
    }
    return false;
  });
};

const getPostRegCard = (cards?: CardType[]) => {
  return cards?.find((c) => {
    if (c.visible?.hide) {
      const states = getVisibilityRuleStates(c.visible);
      if (states.includes(UserEventStates.draft)) {
        return true;
      }
    }
    return false;
  });
};

const eventTypes = Object.keys(EventTypes).filter((v) => !isNaN(Number(v)));

export const generateBuilderConfig = (
  event: Event,
  builder: BuilderConfigDocument,
  concerts?: Concert[],
  langs?: Record<LanguageCode, Event>,
) => {
  const config = Object.assign({}, builder);

  /**
   * Utility function to build `TranslatableContent` for all event config
   */
  const i18n = (
    getContent: (event: Event) => string | undefined,
  ): TranslatableContent => {
    const events: [LanguageCode | "en", Event][] = [
      ["en", event],
      ...(Object.entries(langs || {}) as [LanguageCode, Event][]),
    ];

    return Object.fromEntries(
      events.map(([language, event]) => [language, getContent(event)]),
    ) as TranslatableContent;
  };

  const i18nDupe = (value: string): TranslatableContent =>
    Object.fromEntries(
      ["en", ...Object.keys(langs || {})].map((language) => [language, value]),
    ) as TranslatableContent;

  /* ---------------------
    Show Details
  --------------------- */
  config.fields.startTime = { content: event.config.header.date };

  const isEstablishment =
    (event.root?.geocode?.types || []).findIndex((type) =>
      GOOGLE_PLACES_ESTABLISHMENT_TYPES.includes(type),
    ) !== -1;

  const matchedConcert =
    // First, try exact ID match
    concerts?.find((c) => c.id === builder.fields.concert?.content?.id) ||
    // Fallback to checking date
    concerts?.find(
      (c) =>
        !!c.date &&
        event.config.header.date &&
        luxify(c.date as Timestamp).toISODate() ===
          luxify(event.config.header.date).toISODate(),
    );

  config.fields.concert = {
    content: matchedConcert || {
      address: event.config.header.location || "",
      addressType: isEstablishment ? "establishment" : "address",
      coords: [
        event.root?.coordinates?.latitude || 0,
        event.root?.coordinates?.longitude || 0,
      ],
      date: event.config.header.date,
      geocode: event.root.geocode,
      locationAddedManually: true,
      source: "manual",
      timeZone: event.root?.timeZoneName,
      venue: event.config.header.venue || "",

      // Address Components
      city: getAddressComponent(
        ["locality", "sublocality", "sublocality_level_1"],
        event.root.geocode?.address_components,
      ),
      state: getAddressComponent(
        "administrative_area_level_1",
        event.root.geocode?.address_components,
      ),
      zip: getAddressComponent(
        "postal_code",
        event.root.geocode?.address_components,
      ),
      country: getAddressComponent(
        "country",
        event.root.geocode?.address_components,
      ),
      id: Math.random().toString(36).substring(2),

      // do these fields need to be set?
      title: "",
      venue_id: "",
      updated_at: 0,
      artists: [],
      deletedAt: null,
    },
  };

  /* ---------------------
    Appearance
  --------------------- */
  config.fields = {
    ...config.fields,

    // Selected Template Type
    type: { content: event.config.type },

    // Selected Event Image
    image: { content: event.config.header.image },
  };

  /*  Color Palette
  --------------------- */
  config.fields.colors = {
    content: [
      eventTypes.reduce((t: SelectedTemplateColors, v, i) => {
        if (event.config.colors) {
          t[i] = event.config.colors;
        } else {
          throw new Error("Invalid color config");
        }
        return t;
      }, {}),
    ].concat(config.fields.colors?.content || []),
  };

  /*  Root Image Collection
  --------------------- */
  const matchImageIndex = builder.images?.findIndex((img) =>
    img.src.includes(event.config.header.image),
  );
  if (matchImageIndex > -1) {
    config.images[matchImageIndex].templates.map((t) => ({
      ...t,
      colorsOptions: [event.config.colors, ...t.colorsOptions],
    }));
  } else {
    const colors = event.config.colors;
    if (!colors) {
      throw new Error("Malformed color configuration");
    }

    config.images.unshift({
      src: event.config.header.image,
      templates: eventTypes.map((t, i) => ({
        type: i,
        colorsOptions: [colors],
      })),
    });
  }

  /* ---------------------
    Pre-Registration
  --------------------- */
  const preRegEn = getPreRegCard(event.config.cards);

  config.fields = {
    ...config.fields,
    registerTitle: { content: i18n((e) => e.config.register?.title || "") },
    registerSubtitle: {
      content: i18n((e) => e.config.register?.subtitle || ""),
    },
    registerCtaLabel: {
      content: i18n((e) => e.config.register?.ctalabel || ""),
    },
    preRegistrationHeadline: {
      content: i18n((e) => getPreRegCard(e.config.cards)?.headline || ""),
    },
    preRegistrationButtonLabel: {
      content: i18n(
        (e) =>
          getPreRegCard(e.config.cards)?.ctalabel || preRegEn?.ctalabel || "",
      ),
    },
  };

  /*  Check In
  --------------------- */
  if (!preRegEn?.songs?.length) {
    config.fields = {
      ...config.fields,
      checkInVote: { content: "checkin" },
      preRegistrationBody: {
        content: i18n((e) => e.config.cards?.[0].body || ""),
      },
    };
  }

  /*  Vote
  --------------------- */
  if (!!preRegEn?.songs?.length) {
    const voteType =
      preRegEn.songs.findIndex((s) => s.album) === -1 ? "vote" : "song";
    config.fields = {
      ...config.fields,
      checkInVote: { content: "vote" },
      voteType: { content: voteType },
      preRegistrationSubtitle: {
        content: i18n((e) => e.config.cards?.[0].subtitle || ""),
      },
    };
    if (voteType === "song") {
      config.fields.songOptions = {
        content: preRegEn.songs.map((s) => ({
          name: s.title,
          album: s.album || "",
        })),
      };
    } else {
      config.fields.voteOptions = {
        content: preRegEn.songs.map((_, i) =>
          i18n((e) => getPreRegCard(e.config.cards)?.songs?.[i].title || ""),
        ),
      };
    }
  }

  /*  Privacy Policies
  --------------------- */
  config.fields = {
    ...config.fields,
    privacyPolicyConfirm: { content: true },
  };

  if (event.config.register?.optIns?.artist) {
    config.fields.privacyPolicies = {
      content: [
        {
          entity: "artist",
          name: event.root.artistName || "",
          url: event.config.register?.optIns?.artist,
          isSeparate: false,
          source: "SET.Admin",
        },
      ],
    };
  }

  if (event.config.register?.optIns?.entities) {
    config.fields.privacyPolicies = {
      content: (config.fields.privacyPolicies?.content || []).concat(
        event.config.register.optIns.entities.map((e) => ({
          entity: "other",
          name: e.entityName,
          url: e.url,
          isSeparate: false,
          source: "SET.Admin",
        })),
      ),
    };
  }

  /*  Sweepstakes
  --------------------- */
  if (event.config.register?.hasSweepstakes) {
    config.fields = {
      ...config.fields,
      sweepstakes: { content: true },
      //! fields not populated
      sweepstakesPrize: { content: "customPrize" },
      sweepstakesPrizeTerms: { content: true },
      sweepstakesPrizeCustom: { content: i18nDupe("(Described in copy)") },
    };
  } else {
    config.fields.sweepstakes = { content: false };
  }

  /* ---------------------
    Post-Registration
  --------------------- */
  const postRegEn = getPostRegCard(event.config.cards);
  const postRegType =
    postRegEn?.cta === "social"
      ? "promo"
      : postRegEn?.cta?.includes("http")
      ? "link"
      : "thank";

  config.fields = {
    ...config.fields,
    postRegistration: { content: postRegType },
    postRegistrationHeadline: {
      content: i18n((e) => getPostRegCard(e.config.cards)?.headline || ""),
    },
    postRegistrationBody: {
      content: i18n((e) => getPostRegCard(e.config.cards)?.body || ""),
    },
  };

  const makeHttps = (url?: string) => {
    if (url) {
      url = (url.startsWith("http") ? url : `https://${url}`).replace(
        /^http:/,
        "https:",
      );
    }
    return url;
  };

  /*  Promote Release
  --------------------- */
  if (postRegType === "promo") {
    const release = {
      content: {
        releaseId: Math.random().toString(36).substring(2),
        image: postRegEn?.image || "",
        name: "", //! unable to populate field from data
        type: "album", //! unable to populate field from data
        appleMusic: makeHttps(event.config.social?.apple) || "",
        amazon: makeHttps(event.config.social?.amazon) || "",
        deezer: makeHttps(event.config.social?.deezer) || "",
        iHeartRadio: makeHttps(event.config.social?.iHeartRadio) || "",
        pandora: makeHttps(event.config.social?.pandora) || "",
        spotify: makeHttps(event.config.social?.spotify) || "",
        tidal: makeHttps(event.config.social?.tidal) || "",
        youtubeMusic: makeHttps(event.config.social?.youtube) || "",
      } as Release,
    };
    config.fields = {
      ...config.fields,
      postRegistrationAlbum: release,
      postRegistrationCustomAlbum: release,
      postRegistrationButtonLabel: {
        content: i18n(
          (e) =>
            getPostRegCard(e.config.cards)?.ctalabel ||
            postRegEn?.ctalabel ||
            "",
        ),
      },
    };
  }

  /*  Custom Link
  --------------------- */
  if (postRegType === "link") {
    config.fields = {
      ...config.fields,
      postRegistrationLinkLabel: {
        content: i18n(
          (e) =>
            getPostRegCard(e.config.cards)?.ctalabel ||
            postRegEn?.ctalabel ||
            "",
        ),
      },
      postRegistrationLinkUrl: { content: postRegEn?.cta || "" },
    };
  }

  /* ---------------------
    Social URLs
  --------------------- */
  config.fields = {
    ...config.fields,
    socialLinkFacebook: { content: event.config.socialLinks?.facebook || "" },
    socialLinkInstagram: { content: event.config.socialLinks?.instagram || "" },
    socialLinkShazam: { content: event.config.socialLinks?.shazam || "" },
    socialLinkSpotify: { content: event.config.socialLinks?.spotify || "" },
    socialLinkTiktok: { content: event.config.socialLinks?.tiktok || "" },
    socialLinkTwitter: { content: event.config.socialLinks?.twitter || "" },
    socialLinkYoutube: { content: event.config.socialLinks?.youtube || "" },
  };

  /* ---------------------
    Merch
  --------------------- */
  const merchCard = event.config.cards?.find((c) => !!c.merch?.length);

  if (merchCard?.merch?.length && builder.fields.merchandise?.content?.length) {
    /* Remap merch items */
    const eventMerch = merchCard.merch.map((m) => ({
      img: m.photo || "",
      text: m.name || "",
      url: m.url || "",
      id: Math.random().toString(36).substring(2),
      isSelected: false,
    }));

    /* Find and merge matching merch items */
    const builderMerch = [...builder.fields.merchandise.content].map((m) => {
      const merchMatch = eventMerch.find(({ url }) => url === m.url);
      return {
        ...(merchMatch || m),
        id: m.id,
      };
    });

    /* Filter duplicate items */
    const filteredMerch = eventMerch.filter(
      (m) => builderMerch?.findIndex((c) => c.url === m.url) === -1,
    );

    config.fields.merchandise = {
      content: builderMerch
        .concat(filteredMerch)
        .map((m, i) => ({ ...m, isSelected: !(i > 3) })),
    };
  }

  /* ---------------------
    Post show email custom from
  --------------------- */
  if (event.config.customPostShowEmail?.from) {
    config.customPostShowEmail = { ...event.config.customPostShowEmail };
  } else {
    config.customPostShowEmail = {
      from: `${
        config.fields.displayedName?.content || event.root.artistName
      } and SET.Live`,
    };
  }

  /**
   * Custom Register
   */
  if (event.config.register) {
    config.customRegister = {
      en: event.config.register,
    };

    for (const lang of enumKeys(langs)) {
      if (langs?.[lang].config.register) {
        config.customRegister[lang] = langs?.[lang].config.register;
      }
    }
  }

  return config;
};
