import styled from "styled-components";
import stsLogo from "assets/images/sts-logo.png";
import { SvgLeft } from "melodies-source/Svgs/Left";

export const NavHeader = ({ onClose }: { onClose?: VoidFunction }) => {
  return (
    <Container>
      <CloseButton onClick={onClose}>
        <SvgLeft />
      </CloseButton>
      <FlexRow
        style={{
          flex: "0 0 auto",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LogoImage src={stsLogo} alt="SetTheSet" />
      </FlexRow>
    </Container>
  );
};

const FlexRow = styled.div`
  display: flex;
`;

const LogoImage = styled.img`
  width: 85px;
  object-fit: contain;
`;

const Container = styled(FlexRow)`
  background: #ffffff;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
  color: ${(props) => props.theme.colors.gray4};
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  position: sticky;
  top: 0;
  width: 100%;
  height: 40px;
  z-index: 10;
  flex-shrink: 0;
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: 24px;
  color: ${(props) => props.theme.colors.black80};
`;
