import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  DataProvider,
  SurveyProvider,
  AnalyticsProvider,
  ApplyGlobalStyles,
  PreSavesProvider,
} from "Components";
import { Theme } from "melodies-source/Theme";
import { AuthProvider } from "Components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Spinner } from "melodies-source/Spinner";
import { SurveyConfig } from "@max/common/setfan";
import "./i18n";
import { SpotifyPlaylistProvider } from "Components/SpotifyPlaylistContext";

const Home = React.lazy(() => import("Routes/Home"));

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV || "development",
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    release: process.env.REACT_APP_SHA || "unknown",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
  });
}

if (process.env.REACT_APP_ENV !== "dev") {
  LogRocket.init("fyqxo2/set-fan");
}
setupLogRocketReact(LogRocket);

const IN_PREVIEW_MODE = process.env.REACT_APP_PREVIEW_MODE === "true";
const BrowserBypass = () => {
  const [injectedSurvey, setInjectedSurvey] = useState<SurveyConfig>();
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (IN_PREVIEW_MODE) {
      window.addEventListener("message", handleMessage);
      window.parent.postMessage("init");
    }
    return () => {
      if (IN_PREVIEW_MODE) window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (
    event: MessageEvent<{ survey?: SurveyConfig; page?: number }>,
  ) => {
    setInjectedSurvey(event.data?.survey);
    setPage(event.data?.page);
  };

  if (IN_PREVIEW_MODE) {
    return (
      <BrowserRouter basename="/setfanembed">
        <Routes>
          <Route
            path="/"
            element={
              <AuthProvider>
                <SurveyProvider rawSurvey={injectedSurvey}>
                  <ApplyGlobalStyles />
                  <DataProvider page={page}>
                    <AnalyticsProvider>
                      <PreSavesProvider>
                        <SpotifyPlaylistProvider>
                          <App />
                        </SpotifyPlaylistProvider>
                      </PreSavesProvider>
                    </AnalyticsProvider>
                  </DataProvider>
                </SurveyProvider>
              </AuthProvider>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <Suspense
                fallback={
                  <Spinner style={{ minHeight: "100vh" }} color="#d3d3d3" />
                }
              >
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/:surveyId"
            element={
              <AuthProvider>
                <SurveyProvider>
                  <ApplyGlobalStyles />
                  <DataProvider>
                    <AnalyticsProvider>
                      <PreSavesProvider>
                        <SpotifyPlaylistProvider>
                          <App />
                        </SpotifyPlaylistProvider>
                      </PreSavesProvider>
                    </AnalyticsProvider>
                  </DataProvider>
                </SurveyProvider>
              </AuthProvider>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Theme>
      <BrowserBypass />
    </Theme>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
