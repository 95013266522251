import styled from "styled-components";
import { SvgEdit } from "melodies-source/Svgs/Edit";
import { Caption, H4 } from "melodies-source/Text";
import { QuestionContainer } from "./styles";
import { ModuleType } from "@max/common/setfan";

export const ActionRequiredModule = ({ type }: { type: ModuleType }) => {
  const titleText = () => {
    switch (type) {
      case "PreSaveRelease":
        return "Pre-Save awaiting user input";
      default:
        return "Question awaiting user input";
    }
  };
  const bodyText = () => {
    switch (type) {
      case "Autocomplete":
      case "Dropdown":
      case "MultipleSelectQuestion":
      case "SingleSelectQuestion":
        return "Please add options to see preview";
      case "ImageQuestion":
        return "Please add images to see preview";
      case "image":
        return "Please upload an image to see preview";
      case "SongsQuestion":
        return "Please choose songs to see preview";
      case "TextQuestion":
        return "Please add a question to see preview";
      case "PreSaveRelease":
        return "Please add a pre-save to see preview";
      default:
        return "Please complete question to see preview";
    }
  };
  return (
    <QuestionContainer>
      <ActionRequiredContainer>
        <EditIconContainer>
          <SvgEdit />
        </EditIconContainer>
        <H4 style={{ marginBottom: 8, color: "#666", textAlign: "center" }}>
          {titleText()}
        </H4>
        <Caption style={{ color: "#666", textAlign: "center" }}>
          {bodyText()}
        </Caption>
      </ActionRequiredContainer>
    </QuestionContainer>
  );
};

const ActionRequiredContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #f2f5f7;
  padding: 16px 21px 14px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EditIconContainer = styled.div`
  border-radius: 6.6px;
  background: #dae0e4;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;
