import { SurveyConfigModule } from "@max/common";
import { PreSaveReleaseModuleData } from "@max/common/setfan";
import { usePreSavesContext } from "Components/PreSavesContext";
import { Body1, Body2 } from "melodies-source/Text";
import { useIsMobile } from "melodies-source/utils/hooks";
import styled from "styled-components";
import { ReactComponent as AppleIcon } from "../../images/Apple.svg";
import { ReactComponent as CheckRoundedIcon } from "../../images/check-rounded.svg";
import { ReactComponent as SpotifyIcon } from "../../images/Spotify.svg";
import { Countdown } from "./Countdown";
import { Headline3, Headline4 } from "./styles";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 13px;
  width: 100%;
`;

const ServiceButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 25px 0 6px;
  color: white;
  border: 0;
  border-radius: 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;

  &[data-service="spotify"] {
    background: #1db954;
  }

  &[data-service="apple_music"],
  &[data-service="apple"] {
    background: #fe2c55;
  }
`;

export const PreSaveRelease = (
  config: SurveyConfigModule<PreSaveReleaseModuleData>,
) => {
  const isMobile = useIsMobile();
  const ctx = usePreSavesContext();
  const Title = isMobile ? Headline4 : Headline3;
  const Text = isMobile ? Body2 : Body1;

  if (!config.data || ctx.loading) {
    return null;
  }

  return (
    <Container style={config.style}>
      {ctx.presave ? (
        <>
          <CheckRoundedIcon width={34} height={34} />
          <Title>Pre-Saved</Title>
          <Text
            style={{
              textAlign: "center",
              textWrap: "balance",
              maxInlineSize: "50ch",
            }}
          >
            {config.data.successMessage}
          </Text>
          {config.data.allowFutureReleases &&
            ctx.presave?.allReleases === false && (
              <ServiceButton
                data-service={ctx.presave.service}
                onClick={() => ctx.optInAll?.()}
              >
                {ctx.presave.service === "spotify" ? (
                  <SpotifyIcon width={28} height={28} />
                ) : (
                  <AppleIcon width={28} height={28} />
                )}
                Get All Pre-Saves
              </ServiceButton>
            )}
        </>
      ) : (
        <>
          <Text
            style={{
              textAlign: "center",
              textWrap: "balance",
              maxInlineSize: "50ch",
            }}
          >
            {config.data.message}
          </Text>
          {config.data.preSaveOn.includes("spotify") && (
            <ServiceButton data-service="spotify" onClick={ctx.preSaveSpotify}>
              <SpotifyIcon width={28} height={28} /> Pre-Save On Spotify
            </ServiceButton>
          )}
          {config.data.preSaveOn.includes("apple_music") && (
            <ServiceButton
              data-service="apple_music"
              onClick={ctx.preSaveApple}
            >
              <AppleIcon width={28} height={28} /> Pre-Save On Apple
            </ServiceButton>
          )}
          {ctx.date && <Countdown date={ctx.date} />}
        </>
      )}
    </Container>
  );
};
