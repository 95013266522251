import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Counter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 52px;
  border-radius: 5.844px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
`;

const CounterNumber = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

const CounterTitle = styled.span`
  font-size: 10px;
  font-weight: 400;
`;

interface CountdownProps {
  date: DateTime;
}

export const Countdown = ({ date }: CountdownProps) => {
  const [currentDateTime, setCurrentDateTime] = useState(DateTime.now());

  const remainingTime = date.diff(currentDateTime, [
    "days",
    "hours",
    "minutes",
    "seconds",
  ]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const updateCurrentDateTime = () => {
      setCurrentDateTime(DateTime.now());

      timeout = setTimeout(() => {
        updateCurrentDateTime();
      }, 1000);
    };

    updateCurrentDateTime();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container>
      <Counter>
        <CounterNumber>{Math.floor(remainingTime.days)}</CounterNumber>
        <CounterTitle>Days</CounterTitle>
      </Counter>
      <Counter>
        <CounterNumber>{Math.floor(remainingTime.hours)}</CounterNumber>
        <CounterTitle>hours</CounterTitle>
      </Counter>
      <Counter>
        <CounterNumber>{Math.floor(remainingTime.minutes)}</CounterNumber>
        <CounterTitle>Minutes</CounterTitle>
      </Counter>
      <Counter>
        <CounterNumber>{Math.floor(remainingTime.seconds)}</CounterNumber>
        <CounterTitle>Seconds</CounterTitle>
      </Counter>
    </Container>
  );
};
