import { ReactComponent as SvgHand } from "assets/svg/hand.svg";
import { Body2 } from "melodies-source/Text";
import styled from "styled-components";

export const ClickAndDrag = ({
  className,
  label,
}: {
  className?: string;
  label: string;
}) => {
  return (
    <ClickAndDragContainer className={className}>
      <SvgHand style={{ marginRight: 4 }} />
      <Body2>{label}</Body2>
    </ClickAndDragContainer>
  );
};

const ClickAndDragContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #666;
`;
