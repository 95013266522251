import { DragEndEvent, DragOverlay } from "@dnd-kit/core";
import { findSurveyConfigModule, SurveyConfigModule } from "@max/common";
import { SpotifyPlaylistData } from "@max/common/src/setfan/modules/SpotifyPlaylist";
import { ReactComponent as CheckCircle } from "assets/svg/checkCircle.svg";
import { ClickAndDrag } from "Components/ClickAndDrag";
import { useDataContext } from "Components/DataContext";
import { DndContainer } from "Components/Sortable";
import { useSpotifyPlaylistContext } from "Components/SpotifyPlaylistContext";
import { useSurveyContext } from "Components/SurveyContext";
import { Button } from "melodies-source/Button";
import { SvgSpotify } from "melodies-source/Svgs/Spotify";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Track } from "./Track";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { arrayMove } from "@dnd-kit/sortable";

export const SpotifyPlaylist: React.FC<
  SurveyConfigModule<SpotifyPlaylistData>
> = (props) => {
  const { headerText, successMessage, extraEntriesCount } = props.data || {};
  const { data, finalizedResponse, finalizedResponsePath } = useDataContext();
  const { survey } = useSurveyContext();
  const [songs, setSongs] = useState<Track[]>([]);
  const ctx = useSpotifyPlaylistContext();
  const location = useLocation();

  const question = useMemo(
    () => findSurveyConfigModule(survey.pages, "SongsQuestion"),
    [survey.pages],
  );

  const options = useMemo(
    () => question?.options?.flatMap((option) => option.options ?? []) ?? [],
    [question],
  );

  const savePlaylistOrder = (songs: Track[]) => {
    const playlistOrder: Record<string, number> = {};
    for (let i = 0; i < songs.length; i++) {
      playlistOrder[songs[i].id] = i + 1;
    }

    setDoc(
      doc(getFirestore(), finalizedResponsePath),
      { playlistOrder },
      { merge: true },
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const from = songs.findIndex(({ id }) => id === active.id);
      const to = songs.findIndex(({ id }) => id === over.id);
      const songsSorted = arrayMove(songs, from, to);
      savePlaylistOrder(songsSorted);
      setSongs(songsSorted);
    }
  };

  const shareSpotifyPlaylist = () => {
    const ids = songs.map((song) =>
      song.id.replace("spotify-", "spotify:track:"),
    );
    ctx.createPlaylist?.(ids);
  };

  useEffect(() => {
    const values = (data?.[question?.id]?.value as string[]) ?? [];

    setSongs(
      values
        .map((value, index) => {
          const option = options?.find((option) => option.id === value);
          if (!option) return undefined;
          return {
            id: option.id,
            title: option.label,
            position:
              finalizedResponse?.playlistOrder?.[option.id] || index + 1,
          };
        })
        .filter(Boolean)
        .sort((aSong, bSong) => aSong.position - bSong.position),
    );
  }, [data, question?.id, options, finalizedResponse]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("spotify_playlist") === "false") {
      toast.error("There was an error creating your playlist");
    }
  }, [location.search]);

  useEffect(() => {
    if (extraEntriesCount && finalizedResponse?.playlistCreated) {
      ctx.checkForExtraEntries();
    }
  }, [finalizedResponse?.playlistCreated]);

  return (
    <Container>
      <Divider />
      {finalizedResponse?.playlistCreated ? (
        <SuccessContainer>
          <SucessSpotifyLogoContainer>
            <SucessSpotifyLogo />
            <StyledCheckCircle />
          </SucessSpotifyLogoContainer>
          <SuccessMessage>{successMessage}</SuccessMessage>
        </SuccessContainer>
      ) : (
        <>
          <HeaderText>{headerText}</HeaderText>
          <ShareButton leftIcon={<SvgSpotify />} onClick={shareSpotifyPlaylist}>
            Create Spotify Playlist
          </ShareButton>
          <Playlist>
            <ClickAndDrag label="Click and drag to rearrange song order" />
            <DndContainer
              items={songs}
              onDragEnd={handleDragEnd}
              variant="column"
              options={{ gap: 5 }}
            >
              {({ activeId }) => (
                <>
                  {songs.map((song, index) => (
                    <Track position={index + 1} key={song.id} track={song} />
                  ))}
                  <DragOverlay>
                    {activeId ? (
                      <Track
                        position={
                          songs.findIndex(({ id }) => id === activeId) + 1
                        }
                        track={songs.find(({ id }) => id === activeId)}
                      />
                    ) : null}
                  </DragOverlay>
                </>
              )}
            </DndContainer>
          </Playlist>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
`;

const HeaderText = styled.p`
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  line-height: 1.3;
`;

const ShareButton = styled(Button)`
  background-color: #1db954;
  color: white;

  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    background-color: #1aa24a;
  }
`;

const Playlist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Divider = styled.div`
  background: #e6e9eb;
  height: 2px;
  width: 100%;
  margin: 12px 0;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SucessSpotifyLogoContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const SucessSpotifyLogo = styled(SvgSpotify)`
  width: 100px;
  height: 100px;
  color: #1db954;
`;

const StyledCheckCircle = styled(CheckCircle)`
  position: absolute;
  top: -3px;
  right: 3px;
  color: black;
`;

const SuccessMessage = styled.p`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
`;
