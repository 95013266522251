import React from "react";
import styled from "styled-components";
import { SvgDragLarge } from "melodies-source/Svgs/DragLarge";
import { useSortableAttributes } from "hooks/useSortableAttributes";

export interface Track {
  id: string;
  title: string;
}

interface Props {
  position: number;
  track: Track;
}

export const Track: React.FC<Props> = ({ position, track }) => {
  const { setNodeRef, style, attributes, listeners } = useSortableAttributes(
    track.id,
  );

  return (
    <Container id={module.id} ref={setNodeRef} style={style}>
      <DragIcon {...attributes} {...listeners} />
      <Position>
        <span>TRACK</span>
        <span>{position}</span>
      </Position>
      <Title>{track.title}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  border: 2px solid #dcdcdc;
  background: #ececec;
  padding: 5px 10px;
`;

const DragIcon = styled(SvgDragLarge)`
  flex-shrink: 0;
  color: var(--secondary-text-color);

  &:hover {
    cursor: pointer;
  }
`;

const Position = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  background: white;
  padding: 5px;
  border-radius: 5px;
  border: 0.2px solid #dcdcdc;

  & span:nth-child(1) {
    font-size: 10px;
    color: var(--secondary-text-color);
    font-weight: 600;
  }

  & span:nth-child(2) {
    font-weight: 600;
    line-height: 1;
  }
`;

const Title = styled.span`
  font-weight: 500;
  margin-left: 7px;
`;
