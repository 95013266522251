import { buildApi } from "@max/common/api";
import { getAuth, getIdToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

export const api = buildApi({
  fetchMap: {
    "secure.getPublicKey": `${process.env.REACT_APP_SECURE_WORKER_ENDPOINT}/publicKey`,
    "portal.presave:release":
      process.env.REACT_APP_PREVIEW_MODE === "true"
        ? "/api/presave/release"
        : `${process.env.REACT_APP_PORTAL_URL}/api/presave/release`,
    "portal.setfan.presave": `${process.env.REACT_APP_PORTAL_URL}/api/setfan/presave`,
    "portal.setfan.playlist": `${process.env.REACT_APP_PORTAL_URL}/api/setfan/playlist`,
  },
  getToken: async () => {
    const auth = getAuth();

    if (auth.currentUser) {
      return getIdToken(auth.currentUser);
    }

    console.warn(
      "An attempt is being made to call an API endpoint before we have an authenticated user",
    );

    return "";
  },
  httpsCallable: (name) => httpsCallable(getFunctions(), name),
});
