import { FieldValue, Timestamp } from "firebase/firestore";
import {
  SurveyOptIn,
  ModuleType,
  TextVariant,
  SurveyStatus,
  SurveyConfigOption,
} from "../fanApp/survey";
import { CardVariant } from "../../functions/setfan";
import { RemovePrefix } from "../../utils";
import { ProfilingCategoryId } from "../fanApp/profiling";
import { VirtualOptIns } from "../../creator";
import { SetPageRelease } from "../../setpage";

/**
 * path: /set_fan_builder_surveys/{surveyId}
 */
export interface SurveyBuilderRootDocument {
  artistGroupId: string;
  createdAt: Timestamp;

  draftId: string;

  publishedId?: string;
  status: Extract<SurveyStatus, "draft" | "live" | "completed" | "deleted">;
}

/**
 * Serializable version of `SurveyBuilderConfig`.
 *
 * path: /set_fan_builder_surveys/{surveyId}/versions/{versionId}
 */
export interface SurveyBuilderVersionDocument
  extends Omit<SurveyBuilderConfig, "fields"> {
  createdAt?: FieldValue;
  fields: SurveyBuilderFieldsContentOnly;
  template?: string;
}

export type SurveyBuilderVersionDocumentWithName =
  SurveyBuilderVersionDocument & {
    artistName: string;
  };

/**
 * Version of `SurveyBuilderFields` with only the "content" property.
 */
export type SurveyBuilderFieldsContentOnly = {
  [K in keyof SurveyBuilderFields]: Pick<
    Required<SurveyBuilderFields>[K],
    "content"
  >;
};

/**
 * Survey config used in the UI
 */
export type SurveyBuilderConfig = {
  artistGroupId: string;
  artistPolicy?: string;
  disclaimers?: string[];
  fields: SurveyBuilderFields;
  flags?: SurveyBuilderFlags;
  optIns: SurveyBuilderOptIn[];
  surveyQuestions: BuilderConfigModule[];
  virtualOptIns?: VirtualOptIns;
};
export type SurveyBuilderOptIn = SurveyOptIn & { separate?: boolean };

export type TranslateableModuleContent = {
  [K in SurveyLanguageCode]?: string;
} & { en: string };

export const SurveyLanguages = {
  es: "Spanish",
  fr: "French",
  de: "German",
} as const;

export type SurveyBuilderFlags = {
  showContestConfirmationMessage: Nullable<boolean>;
};

export type SurveyLanguageCode = keyof typeof SurveyLanguages | "en";

export type SurveyLanguageName =
  (typeof SurveyLanguages)[keyof typeof SurveyLanguages];

export type SurveyTranslatableContent<T = string> = {
  [index in SurveyLanguageCode]?: T;
} & {
  en: T;
};

export type LayoutContent = "default" | "angular" | "wave" | "boxy";

export type PhoneNumberValidation = "required" | "optional" | "no-phone";

export type TextureContent =
  | "none"
  | "distressed"
  | "lights"
  | "watercolor"
  | "waves"
  | "paper"
  | "water"
  | "geometric";

type Nullable<T> = T | null;

interface BuilderField<T> {
  content: T;
  getValidation: (b: SurveyBuilderConfig) => string | false;
  isTranslateable: T extends SurveyTranslatableContent ? true : false;
  isDirty: boolean;
  isTouched: boolean;
}

export interface SurveyBuilderFields {
  description: BuilderField<SurveyTranslatableContent>;
  header: BuilderField<SurveyTranslatableContent>;
  body: BuilderField<SurveyTranslatableContent>;
  image: BuilderField<SurveyTranslatableContent>;
  backgroundImage: BuilderField<SurveyTranslatableContent>;
  backgroundColor: BuilderField<string>;
  buttonColor: BuilderField<string>;
  layout: BuilderField<LayoutContent>;
  texture: BuilderField<TextureContent>;
  darkMode: BuilderField<boolean>;
  isContest: BuilderField<boolean>;
  contestPrize: BuilderField<SurveyTranslatableContent>;
  contestPrizeValue: BuilderField<Nullable<number>>;
  contestEndDate: BuilderField<Nullable<Timestamp>>;
  contestCustomPrizeDescription: BuilderField<SurveyTranslatableContent>;
  hasContestCustomRules: BuilderField<Nullable<boolean>>;
  contestCustomRulesUrl: BuilderField<SurveyTranslatableContent>;
  numberOfCategories: BuilderField<string>;
  displayedCategories: BuilderField<CategoryOptionId[]>;
  demographicsIntroText: BuilderField<SurveyTranslatableContent>;
  fooddrinkIntroText: BuilderField<SurveyTranslatableContent>;
  alcoholIntroText: BuilderField<SurveyTranslatableContent>;
  clothingIntroText: BuilderField<SurveyTranslatableContent>;
  vehicleIntroText: BuilderField<SurveyTranslatableContent>;
  entertainmentIntroText: BuilderField<SurveyTranslatableContent>;
  phoneNumberValidation: BuilderField<Nullable<PhoneNumberValidation>>;
  thankYouHeader: BuilderField<SurveyTranslatableContent>;
  thankYouBody: BuilderField<SurveyTranslatableContent>;
  hasFeaturedCta: BuilderField<Nullable<boolean>>;
  featuredCtaHeaderText: BuilderField<SurveyTranslatableContent>;
  featuredCtaButtonText: BuilderField<SurveyTranslatableContent>;
  featuredCtaUrl: BuilderField<SurveyTranslatableContent>;
  useCustomTermsAndPrivacyPolicy: BuilderField<Nullable<boolean>>;
  customTermsAndPrivacyPolicy: BuilderField<SurveyTranslatableContent>;
  termsAndPrivacyPolicySeparateLineItems: BuilderField<SurveyTranslatableContent>;
  useCustomSmsOptIn: BuilderField<Nullable<boolean>>;
  customSmsOptIn: BuilderField<SurveyTranslatableContent>;
  hasIntroFooter: BuilderField<Nullable<boolean>>;
  introFooter: BuilderField<SurveyTranslatableContent>;
  hasThankYouFooter: BuilderField<Nullable<boolean>>;
  thankYouFooter: BuilderField<SurveyTranslatableContent>;
  hasPreSave?: BuilderField<Nullable<boolean>>;
  preSaveRelease?: BuilderField<Nullable<SetPageRelease>>;
  hasPreSaveExtraContestEntries?: BuilderField<Nullable<boolean>>;
  preSaveExtraContestEntries?: BuilderField<Nullable<number>>;
  preSaveMessage?: BuilderField<Nullable<SurveyTranslatableContent>>;
  preSaveThankYouMessage?: BuilderField<Nullable<SurveyTranslatableContent>>;
  preSaveContestMessage?: BuilderField<Nullable<SurveyTranslatableContent>>;
  preSaveContestThankYouMessage?: BuilderField<
    Nullable<SurveyTranslatableContent>
  >;
  hasSpotifyPlaylist?: BuilderField<Nullable<boolean>>;
  spotifyPlaylistText?: BuilderField<SurveyTranslatableContent>;
  spotifyPlaylistGivesSweepstakesEntries?: BuilderField<Nullable<boolean>>;
  spotifyPlaylistSweepstakesEntriesCount?: BuilderField<Nullable<number>>;
  spotifyPlaylistSuccessMessage?: BuilderField<SurveyTranslatableContent>;
}

export type BuilderConfigOption = {
  id?: string;
  label?: TranslateableModuleContent;
  altLabel?: TranslateableModuleContent;
  src?: TranslateableModuleContent;
  htmlLabel?: TranslateableModuleContent;
  // for user defined single and multi select options
  userDefined?: boolean;
  // for songs questions
  options?: BuilderConfigOption[];
};

type BaseBuilderConfigModule<Data = unknown> = {
  id?: string;
  noBorder?: boolean;
  style?: CSSServerProperties;
  tag?: string;
  type: ModuleType;
  actionRequired?: boolean;
  data?: Data;
};

export type LabeledBuilderConfigModule = BaseBuilderConfigModule & {
  label: TranslateableModuleContent;
};

export type InputBuilderConfigModule = LabeledBuilderConfigModule & {
  required: boolean;
};

export type SelectBuilderConfigModule = InputBuilderConfigModule & {
  options?: BuilderConfigOption[];
  randomizeOptions?: boolean;
};

export type CardBuilderConfigModule = BaseBuilderConfigModule & {
  backgroundColor?: string;
  compact: boolean;
  image?: { src: string; align: "left" | "center" };
  modules?: BuilderConfigModule[];
  url?: string;
  variant: CardVariant;
};

export type PageBuilderConfigModule = BaseBuilderConfigModule & {
  modules: BuilderConfigModule[];
  compact: boolean;
};

export type ProgressBuilderConfigModule = BaseBuilderConfigModule;

export type ContainerBuilderConfigModule = BaseBuilderConfigModule & {
  modules: BuilderConfigModule[];
};

export type ImageBuilderConfigModule = LabeledBuilderConfigModule & {
  src?: TranslateableModuleContent;
};

export type ImageQuestionBuilderConfigModule = SelectBuilderConfigModule & {
  showLabels?: boolean;
};

export type MultiSelectBuilderConfigModule = Omit<
  SelectBuilderConfigModule,
  "required"
> & {
  requiredMin?: number;
  requiredMax?: number;
};

export type ProfilingBuilderConfigModule = BaseBuilderConfigModule & {
  index: number;
  force?: ProfilingCategoryId;
};

export type RankedBuilderConfigModule = InputBuilderConfigModule & {
  displayOrder?: "asc" | "desc";

  /**
   * the label to be appended to the "lowest" option.
   * eg. rankLow: "least", label for option 1 would be `1 (least)`
   */
  rankLow: TranslateableModuleContent;

  /**
   * the label to be appended to the "highest" option.
   * eg. rankHigh: "most", rankRange: 5, label for option 5 would be `5 (most)`
   */
  rankHigh: TranslateableModuleContent;

  /**
   * the maximum value for the ranked question
   */
  rankRange: number;
};

export type SingleSelectBuilderConfigModule = SelectBuilderConfigModule & {
  /**
   * we use this flag to keep a module from being shown to the user and from being validated on submit.
   * currently, this is only useable on single select modules since we use it to inject eventIds into
   * the survey form without user input
   */
  hidden?: boolean;
};

export type SocialMediaBuilderConfigModule = BaseBuilderConfigModule & {
  // the color of social anchor tags
  color?: string;
  facebook?: string;
  instagram?: string;
  snapchat?: string;
  tiktok?: string;
  twitter?: string;
};

export type SongsQuestionBuilderConfigModule = SelectBuilderConfigModule & {
  requiredMax?: number;
};

export type SubmitBuilderConfigModule = BaseBuilderConfigModule & {
  href?: TranslateableModuleContent;
  label: TranslateableModuleContent;
  complete: boolean;
};

export type TextBuilderConfigModule = LabeledBuilderConfigModule & {
  caption?: TranslateableModuleContent;
  variants: { mobile: TextVariant; desktop: TextVariant };
};

export type TextQuestionBuilderConfigModule = InputBuilderConfigModule & {
  multiline?: boolean;
};

export type BuilderConfigModule<Data = unknown> =
  | BaseBuilderConfigModule<Data>
  | LabeledBuilderConfigModule
  | InputBuilderConfigModule
  | SelectBuilderConfigModule
  | CardBuilderConfigModule
  | PageBuilderConfigModule
  | ContainerBuilderConfigModule
  | ImageBuilderConfigModule
  | ImageQuestionBuilderConfigModule
  | MultiSelectBuilderConfigModule
  | ProfilingBuilderConfigModule
  | RankedBuilderConfigModule
  | SingleSelectBuilderConfigModule
  | SongsQuestionBuilderConfigModule
  | SubmitBuilderConfigModule
  | TextBuilderConfigModule
  | TextQuestionBuilderConfigModule;

export const CATEGORIES_OPTIONS_IDS: readonly RemovePrefix<
  "profilecategory",
  Exclude<ProfilingCategoryId, "profilecategory.email" | "profilecategory.core">
>[] = [
  "demographics",
  "fooddrink",
  "alcohol",
  "clothing",
  "vehicle",
  "entertainment",
] as const;

export type CategoryOptionId = (typeof CATEGORIES_OPTIONS_IDS)[number];

export type CSSServerProperties = Record<string, unknown>;

export interface QuestionEditProps {
  options?: SurveyConfigOption[];
  question: string;
  setlist?: { requiredMax: number };
  image?: { src: string; label?: string };
  rank?: { low: string; high: string; range: number };
  text?: { caption?: string };
}
