import {
  createContext,
  useState,
  useContext,
  useEffect,
  FC,
  PropsWithChildren,
} from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firebaseApp, useAuth, useSurveyContext } from "Components";
import {
  getFirestore,
  setDoc,
  doc,
  DocumentReference,
  serverTimestamp,
  Timestamp,
  Bytes,
} from "firebase/firestore";
import { useQuery } from "./useQuery";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Spinner } from "melodies-source/Spinner";
import {
  SurveyResponse,
  SurveyResponses,
  SurveySubmission,
} from "@max/common/functions/setfan";
import { encryptJsonObject, SecurePayload } from "@max/common/crypto";
import { getPublicKey } from "../crypto";

interface DataContextProps {
  data: Record<string, SurveyResponse>;
  setData: Function;
  view: number;
  onSubmit: (d: SurveyResponses) => void;
  setView: Function;
  showValidation: boolean;
  setShowValidation: Function;
  isSubmissionPage: boolean;
  finalizedResponse?: SurveySubmission;
  finalizedResponsePath?: string;
}

const DataContext = createContext<DataContextProps>({} as DataContextProps);

export const DataProviderReal: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const {
    survey,
    progressiveProfiling,
    setEmail,
    id: surveyId,
    setIsEmailInjected,
  } = useSurveyContext();
  const db = getFirestore(firebaseApp);

  const [previousSubmissionsRef, setPreviousSubmissionsRef] =
    useState<DocumentReference<SurveySubmission>>();
  const [previousSubmissions] = useDocumentData(previousSubmissionsRef);
  const [view, setView] = useState(0);
  const [responseData, setResponseData] = useState<SurveySubmission>();
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const [finalizedResponsesRef, setFinalizedResponsesRef] =
    useState<DocumentReference<SurveySubmission>>();
  const [finalizedResponse] = useDocumentData(finalizedResponsesRef);
  const finalizedResponsePath = `sts3_surveys/${surveyId}/submissions/${user?.uid}`;

  useEffect(() => {
    if (!previousSubmissionsRef) {
      // we should not consider anything in the previous submissions to be valid until the survey is submitted.
      // previously we wrote this simultaneously with partial response data, but as unlikely
      // as this is to occur, a user could complete a partial survey, load a new one for the same
      // artist in the window, and not answer more relevant questioning (since our data would assume
      // they already answered questions for another survey)
      setPreviousSubmissionsRef(
        doc(
          db,
          `sts3_users`,
          `${survey.artistGroupId}#${user?.uid}`,
        ) as DocumentReference<SurveySubmission>,
      );
      // we only listen to this subcollection when consuming responses (this is written on finalization)
      setFinalizedResponsesRef(
        doc(db, finalizedResponsePath) as DocumentReference<SurveySubmission>,
      );
    }
  }, [survey.artistGroupId, user?.uid, db, surveyId, previousSubmissionsRef]);

  useEffect(() => {
    if (previousSubmissions) {
      setResponseData((responseData) => ({
        ...responseData,
        ...previousSubmissions,
        responses: {
          ...responseData?.responses,
          ...previousSubmissions.responses,
        },
        artistGroupId: survey.artistGroupId,
      }));

      if (previousSubmissions.responses?.email?.value) {
        setIsEmailInjected(true);
      }
    }
  }, [previousSubmissions, setIsEmailInjected, survey.artistGroupId]);

  // checks if the current browser session has completed this survey. if so, jump to end.
  useEffect(() => {
    if (
      (
        previousSubmissions?.responses?.completeSurveys as string[] | undefined
      )?.includes(surveyId) &&
      survey.pages?.length
    ) {
      setView(survey.pages.length - 1);
    }
    setTimeout(() => setLoading(false), 500);
  }, [survey, previousSubmissions, surveyId]);

  const setData = (d: SurveyResponses) => {
    setResponseData({
      ...responseData,
      responses: {
        ...d,
      },
    });
  };
  // add text opt in if progressive profiling shows the user as already opting in
  useEffect(() => {
    if (progressiveProfiling?.optInText) {
      setResponseData((respData) => ({
        ...respData,
        responses: {
          ...respData.responses,
          optIn: { value: ["optInText"] },
        },
      }));
    }
  }, [progressiveProfiling]);

  const onSubmit = async (d: SurveyResponses) => {
    const publicKey = await getPublicKey({
      id: surveyId,
      item: "survey",
    });

    const responses: SurveySubmission["responses"] = {
      ...d,
      virtualOptIn: {
        value: Object.values(survey.virtualOptIns ?? {}).flatMap(
          (v: string[]) => v,
        ),
      },
    };

    const { encryptedData, encryptedSymmetricKey, iv } =
      await encryptJsonObject({
        jsonObject: responses,
        publicKeyPem: publicKey.key,
      });

    const secure: SecurePayload<Bytes> = {
      id: publicKey.id,
      key: Bytes.fromUint8Array(encryptedSymmetricKey),
      data: Bytes.fromUint8Array(encryptedData),
      iv: Bytes.fromUint8Array(iv),
    };

    const finalResponses: SurveySubmission = {
      ...responseData,
      responses,
      secure,
      createdAt: serverTimestamp() as Timestamp,
      artistGroupId: survey.artistGroupId,
    };

    setDoc(finalizedResponsesRef, finalResponses);
    setDoc(
      previousSubmissionsRef,
      {
        ...responseData,
        responses: d,
        updatedAt: serverTimestamp(),
        artistGroupId: survey.artistGroupId,
      },
      { merge: true },
    );
  };

  const params = useQuery();
  const [userLoading, setUserLoading] = useState(true);

  /**
   * If a user is given a link with query params, parse them to look for `ref`, the hashed email of a setlive user
   * and `setliveEvent`, the event id of the setlive event this user was referred from. If a setliveEvent parameter
   * exists, we set that in the data context so it is submitted with the survey. If there is a ref, we call a
   * cloud function to check if there is a corresponding fan profile, and if there is, whether or not they have
   * taken the survey already. If they have not taken it and there is a matching user, the email is returned
   * and it is set in the data context so we don't ask the user again.
   */
  useEffect(() => {
    const ref = params.get("ref");
    const setliveEvent = params.get("setliveEvent");

    // if setlive event id set in response data under question value
    if (setliveEvent && !responseData?.responses?.setliveEvent?.value) {
      setResponseData(
        (respData) =>
          ({
            ...respData,
            responses: {
              ...respData?.responses,
              setliveEvent: { value: setliveEvent },
            },
          }) as SurveySubmission,
      );
    }

    // if a `ref` query parameter exists, call `checkExistingUser` to return the email
    // address of an existing user who has not taken the survey yet.
    // :: checkExistingUser algo =>
    // :::: if a fan with the given email hash does not exist for this artist group, return null
    // :::: if a fan with the given email hash exists for the artist group but has taken the survey, return null
    // :::: if a fan matches and has not taken the survey, return the user email to populate at load
    if (ref && !responseData?.responses?.email?.value) {
      (async () => {
        try {
          const { data } = await httpsCallable<
            unknown,
            { user?: { email: string } }
          >(
            getFunctions(),
            "survey-checkExistingUser",
          )({
            artistGroupId: survey.artistGroupId,
            surveyId: surveyId,
            userHash: ref,
          });

          if (data.user?.email) {
            setResponseData(
              (respData) =>
                ({
                  ...respData,
                  responses: {
                    ...respData?.responses,
                    email: { value: data.user?.email },
                  },
                }) as SurveySubmission,
            );
            setEmail(data.user.email);
            setIsEmailInjected(true);
          }
        } catch (err) {
          console.error("unable to check existing user", {
            error: (err as Error).message,
          });
        } finally {
          setUserLoading(false);
        }
      })();
    } else {
      setUserLoading(false);
    }
  }, [
    survey.artistGroupId,
    setIsEmailInjected,
    setEmail,
    params,
    surveyId,
    responseData?.responses?.email?.value,
    responseData?.responses?.setliveEvent?.value,
  ]);

  if (loading || userLoading) {
    return <Spinner style={{ minHeight: "100vh" }} />;
  }

  const value = {
    data: responseData?.responses || {},
    setData,
    view,
    setView,
    onSubmit,
    showValidation,
    setShowValidation,
    // used to determine button text for continue/submit button since we dynamically change
    // the pages on survey load (for determining profiling blocks/pages)
    isSubmissionPage: view === survey.pages?.length - 2,
    finalizedResponse,
    finalizedResponsePath,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

const DataProviderMock: FC<PropsWithChildren<{ page?: number }>> = ({
  children,
  page,
}) => {
  const [data, setData] = useState({});
  const { survey } = useSurveyContext();

  const value = {
    data,
    setData,
    view: page,
    setView: () => {},
    onSubmit: () => {},
    showValidation: false,
    setShowValidation: () => {},
    isSubmissionPage: page === survey.pages?.length - 2,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const DataProvider: FC<PropsWithChildren<{ page?: number }>> =
  process.env.REACT_APP_PREVIEW_MODE === "true"
    ? DataProviderMock
    : DataProviderReal;

export const useDataContext = () => useContext(DataContext);
