import { createContext, ReactNode, useCallback, useContext } from "react";
import { useAuth } from "./Auth";
import { SPOTIFY_SCOPES } from "./SPOTIFY_SCOPES";
import { useSurveyContext } from "./SurveyContext";
import { api } from "api";

interface Value {
  createPlaylist?: (ids: string[]) => void;
  checkForExtraEntries?: () => Promise<void>;
}

const Context = createContext<Value>({});

export const useSpotifyPlaylistContext = () => useContext(Context);

interface Props {
  children: ReactNode;
}

const Mock = ({ children }: Props) => {
  return <Context.Provider value={{}}>{children}</Context.Provider>;
};

const Provider = ({ children }: Props) => {
  const { user } = useAuth();
  const { id: surveyId, survey } = useSurveyContext();

  const createPlaylist = useCallback(
    (ids: string[]) => {
      const url = new URL("https://accounts.spotify.com/authorize");
      url.searchParams.set(
        "client_id",
        process.env.REACT_APP_SPOTIFY_CLIENT_ID,
      );
      url.searchParams.set(
        "redirect_uri",
        `${process.env.REACT_APP_SPOTIFY_WORKER_ENDPOINT}/playlist`,
      );
      url.searchParams.set("response_type", "code");
      url.searchParams.set("scope", SPOTIFY_SCOPES.join(","));
      url.searchParams.set(
        "state",
        JSON.stringify({
          uid: user?.uid,
          playlistName: btoa(`My ${survey.artistName} Setlist`),
          trackIds: ids,
          surveyId,
          redirectUri: encodeURIComponent(window.location.href),
        }),
      );
      window.location.href = url.toString();
    },
    [user?.uid, surveyId, survey.artistName],
  );

  const checkForExtraEntries = async () => {
    await api.call("portal.setfan.playlist", { surveyId });
  };

  return (
    <Context.Provider
      value={{
        createPlaylist,
        checkForExtraEntries,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const SpotifyPlaylistProvider =
  process.env.REACT_APP_PREVIEW_MODE === "true" ? Mock : Provider;
