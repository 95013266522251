import { findPreSaveSurveyConfigModule } from "@max/common";
import { PresaveSource, ReleasePresave } from "@max/common/src/artists/presave";
import { api } from "api";
import {
  collection,
  getFirestore,
  limit,
  query,
  Query,
  setDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet";
import { useAuth } from "./Auth";
import { SPOTIFY_SCOPES } from "./SPOTIFY_SCOPES";
import { useSurveyContext } from "./SurveyContext";

interface Value {
  presave?: ReleasePresave;
  loading?: boolean;
  loadingMusickit?: boolean;
  date?: DateTime | null;
  preSaveSpotify?: () => void;
  preSaveApple?: () => void;
  optInAll?: () => void;
}

const Context = createContext<Value>({});

const SOURCE: PresaveSource = "setfan";

const usePreSaveModule = () => {
  const { survey } = useSurveyContext();

  return useMemo(
    () => findPreSaveSurveyConfigModule(survey.pages),
    [survey.pages],
  );
};

const useReleaseDate = (id?: string) => {
  const [date, setDate] = useState<DateTime>(null);

  useEffect(() => {
    (async () => {
      if (id) {
        const { data } = await api.call("portal.presave:release", { id });

        if (data?.data?.releaseDate) {
          setDate(DateTime.fromISO(data.data.releaseDate));
        }
      }
    })();
  }, [id]);

  return date;
};

interface Props {
  children?: React.ReactNode;
}

const Mock = ({ children }: Props) => {
  const mod = usePreSaveModule();
  const date = useReleaseDate(mod?.data?.releaseId);
  return <Context.Provider value={{ date }}>{children}</Context.Provider>;
};

const Provider = ({ children }: Props) => {
  const { user } = useAuth();
  const { id: surveyId, survey } = useSurveyContext();
  const mod = usePreSaveModule();
  const date = useReleaseDate(mod?.data?.releaseId);

  const [presaves, loading] = useCollection(
    mod?.data?.releaseId && user?.uid && survey?.artistGroupId
      ? (query(
          collection(getFirestore(), "release_presaves"),
          where("uid", "==", user.uid),
          where("releaseIds", "array-contains", mod.data.releaseId),
          where("artistGroupId", "==", survey.artistGroupId),
          limit(1),
        ) as Query<ReleasePresave>)
      : null,
  );

  const presave = presaves?.docs?.[0];
  const hasPresave = presave?.exists();
  const hasApple = mod?.data?.preSaveOn?.includes("apple_music");

  const preSaveSpotify = useCallback(() => {
    const url = new URL("https://accounts.spotify.com/authorize");
    url.searchParams.set("client_id", process.env.REACT_APP_SPOTIFY_CLIENT_ID);
    url.searchParams.set(
      "redirect_uri",
      process.env.REACT_APP_SPOTIFY_WORKER_ENDPOINT,
    );
    url.searchParams.set("response_type", "code");
    url.searchParams.set("scope", SPOTIFY_SCOPES.join(","));
    url.searchParams.set(
      "state",
      JSON.stringify({
        uid: user?.uid,
        releaseId: mod?.data?.releaseId,
        redirectUri: encodeURIComponent(window.location.href),
        source: SOURCE,
      }),
    );
    window.location.href = url.toString();
  }, [mod, user]);

  const preSaveApple = useCallback(async () => {
    try {
      const musicCode = await window.MusicKit.getInstance().authorize();

      await fetch(process.env.REACT_APP_APPLE_WORKER_ENDPOINT, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          code: musicCode,
          releaseId: mod.data.releaseId,
          uid: user?.uid,
          source: SOURCE,
          redirectUri: "",
        }),
      });
    } catch (error) {
      console.error("there was a problem calling preSaveApple", error);
    }
  }, [mod, user]);

  const optInAll = useCallback(() => {
    setDoc(
      presave?.ref,
      {
        allReleases: true,
        allReleasesSource: mod?.data?.releaseId,
      },
      {
        merge: true,
      },
    );
  }, [mod, presave]);

  useEffect(() => {
    if (hasPresave) {
      api.call("portal.setfan.presave", {
        surveyId,
      });
    }
  }, [hasPresave, surveyId]);

  useEffect(() => {
    if (hasApple) {
      const controller = new AbortController();

      const handler = async () => {
        try {
          const response = await fetch(
            process.env.REACT_APP_APPLE_WORKER_ENDPOINT,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify({ action: "token" }),
            },
          );

          const { token }: { token: string } = await response.json();

          await window.MusicKit.configure({
            developerToken: token,
            app: { name: "SETBio", build: "1" },
          });
        } catch (error) {
          console.error(
            "there was a problem calling MusicKit.configure",
            error,
          );
        }
      };

      document.addEventListener("musickitloaded", handler, {
        signal: controller.signal,
      });

      return () => controller.abort();
    }
  }, [hasApple]);

  return (
    <Context.Provider
      value={{
        presave: presave?.data(),
        loading,
        date,
        preSaveApple,
        preSaveSpotify,
        optInAll,
      }}
    >
      {hasApple && (
        <Helmet>
          <script
            src="https://js-cdn.music.apple.com/musickit/v3/musickit.js"
            async={true}
          />
        </Helmet>
      )}
      {children}
    </Context.Provider>
  );
};

export const usePreSavesContext = () => useContext(Context);

export const PreSavesProvider =
  process.env.REACT_APP_PREVIEW_MODE === "true" ? Mock : Provider;
