import { BuilderConfigModule } from "../creator";
import { ModuleType, SurveyConfigModule } from "../fanApp";
import { SurveyModule, SurveyModuleProps } from "./base";

export type SpotifyPlaylistData = {
  headerText: string;
  extraEntriesCount: number | null;
  successMessage: string;
};

export class SpotifyPlaylistModule extends SurveyModule<SpotifyPlaylistData> {
  protected _type: ModuleType = "SpotifyPlaylist";

  constructor(props: SurveyModuleProps<SpotifyPlaylistData>) {
    super(props);
    this.actionRequired = !props.data;
  }

  validate(): this is Required<SpotifyPlaylistModule> {
    return this.data ? true : false;
  }

  toBuilderConfig(): BuilderConfigModule<SpotifyPlaylistData> {
    return {
      id: this.id,
      type: this.type,
      actionRequired: this.actionRequired,
      style: this.style,
      data: this.data,
    } as BuilderConfigModule<SpotifyPlaylistData>;
  }

  toSurveyConfig(): SurveyConfigModule {
    return {
      id: this.id,
      type: this.type,
      actionRequired: this.actionRequired,
      style: this.style,
      data: this.data,
    } as SurveyConfigModule<SpotifyPlaylistData>;
  }

  editProps(): undefined {
    return undefined;
  }
}
