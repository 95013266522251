import { SetPageReleaseServices } from "../../setpage";
import { BuilderConfigModule } from "../creator";
import { ModuleType, SurveyConfigModule } from "../fanApp";
import { SurveyModule, SurveyModuleProps } from "./base";

export type PreSaveReleaseModuleData = {
  releaseId: string;
  preSaveOn: SetPageReleaseServices[];
  allowFutureReleases?: boolean | null;
  extraContestEntries?: number | null;
  message?: string;
  successMessage?: string;
};

export class PreSaveReleaseModule extends SurveyModule<PreSaveReleaseModuleData> {
  protected _type: ModuleType = "PreSaveRelease";

  constructor(props: SurveyModuleProps<PreSaveReleaseModuleData>) {
    super(props);
    this.actionRequired = !props.data;
  }

  validate(): this is Required<PreSaveReleaseModule> {
    return this.data ? true : false;
  }

  toBuilderConfig(): BuilderConfigModule<PreSaveReleaseModuleData> {
    return {
      id: this.id,
      type: this.type,
      actionRequired: this.actionRequired,
      style: this.style,
      data: this.data,
    } as BuilderConfigModule<PreSaveReleaseModuleData>;
  }

  toSurveyConfig(): SurveyConfigModule {
    return {
      id: this.id,
      type: this.type,
      actionRequired: this.actionRequired,
      style: this.style,
      data: this.data,
    } as SurveyConfigModule<PreSaveReleaseModuleData>;
  }

  editProps(): undefined {
    return undefined;
  }
}
