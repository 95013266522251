import type { DateTime } from "luxon";

import { Timestamp } from "../compat";
import {
  Colors as ColorsOption,
  EventConfig,
  EventTypes,
  Merch,
  Register,
  SocialLinkName,
  SocialLinkNames,
  SocialLinks,
  UserEventStates,
} from "../event";
import { filterUndefined, removeUndefined } from "../utils";
import { generatePostRegText } from "./postregistration";
import { generatePreRegText } from "./preregistration";
import { Languages as PrivacyLanguages, generatePrivacyText } from "./privacy";
import { SelectedTemplateColors, getColorConfig } from "./colors";
import { getAddressComponent } from "./generateBuilderConfig";
import { CountryCode } from "../location";
import { Show, TourSource } from "../api/tour";

export { generatePreRegText, PrizeOption } from "./preregistration";
export { generatePostRegText } from "./postregistration";
export * from "./generateCustomFields";
export * from "./colors";
export * from "./generateBuilderConfig";
export * from "./privacy";

// From spotify
/**
 * - album
 * - single
 * - appears_on (ignore??)
 * - compilation (ignore??)
 */

export const StreamingServiceNames = [
  { path: "appleMusic", name: "Apple Music" },
  { path: "amazon", name: "Amazon Music" },
  { path: "pandora", name: "Pandora" },
  { path: "spotify", name: "Spotify" },
  { path: "youtubeMusic", name: "YouTube Music" },
  { path: "deezer", name: "Deezer" },
  { path: "iHeartRadio", name: "iHeartRadio" },
  { path: "tidal", name: "Tidal" },
] as const;

export type StreamingService = (typeof StreamingServiceNames)[number]["path"];

export type StreamingServices = { [K in StreamingService]: string };

/**
 * This isn't used currently, but it is a nifty trick
 */
export type Socials = {
  [K in keyof BuilderConfigDocument["fields"] as K extends `socialLink${infer Name}`
    ? Lowercase<Name> extends SocialLinkName
      ? Lowercase<Name>
      : never
    : never]: string;
};

/**
 * Extracts social links from bulder config doc.
 */
export const getSocialLinks = (config: BuilderConfigDocument): SocialLinks => {
  const getSocialKey = (key: string): SocialLinkName | void => {
    const { name } = key.match(/socialLink(?<name>.*)/)?.groups || {};
    if (name) {
      if (SocialLinkNames.includes(name.toLowerCase() as SocialLinkName)) {
        return name.toLowerCase() as SocialLinkName;
      }
    }
  };

  return Object.fromEntries(
    Object.entries(config.fields || {})
      .map(([key, value]): [SocialLinkName, string] | undefined => {
        const k = getSocialKey(key);
        value.content;
        if (k && typeof value.content === "string") {
          return [k, value.content];
        }
        return undefined;
      })
      .filter(filterUndefined),
  );
};

export interface Release extends Partial<StreamingServices> {
  image: string;
  name: string;
  released?: number;
  releaseId?: string;
  type: "album" | "single";
}

export interface TypesenseConcert<T = Date | Timestamp> {
  id: string;
  title: string;
  date: T;
  venue_id: string;
  venue: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  coords: [lat: number, lon: number];
  source: string;
  updated_at: number;
  artists: string[];
  timeZone?: string;
  geocode?: google.maps.GeocoderResult;
}

type SourceData = Pick<Show, "id" | "url"> & {
  artist_id: string;
  artists: string[];
  removed: boolean;
  vipUrl?: string;
};

export type Sources = { [Source in TourSource]?: SourceData | null };

export interface Concert<T = Date | Timestamp> extends TypesenseConcert<T> {
  setliveEventId?: string;
  locationAddedManually?: boolean;
  addressType?: "establishment" | "address";
  deletedAt: T | null;

  /**
   *
   */
  sources?: Sources;
}

export type ManualConcert = Pick<
  Concert,
  | "address"
  | "coords"
  | "timeZone"
  | "geocode"
  | "venue"
  | "locationAddedManually"
> & { date: string };

export interface Template {
  type: EventTypes; // 0,1,2,3,4
  colorsOptions: ColorsOption[];
}

export interface Image {
  src: string;
  templates: Template[];
}

export interface Merchandise {
  img: string;
  text: string;
  url: string;
  id: string;
  isSelected: boolean;
}

export interface Song {
  name: string;
  album: string;
  spotifyId?: string;
  isCover?: boolean;
}

export const Languages = {
  es: "Spanish",
  fr: "French",
  de: "German",
} as const;
export type LanguageCode = keyof typeof Languages;
export type LanguageName = (typeof Languages)[keyof typeof Languages];

export type TranslatableContent = {
  [index in LanguageCode]?: string;
} & {
  en: string;
};

export const CheckInVoteOptions = ["checkin", "vote"] as const;
export type CheckInVoteOption = (typeof CheckInVoteOptions)[number];

export const VoteTypeOptions = ["song", "vote"] as const;
export type VoteTypeOption = (typeof VoteTypeOptions)[number];

export const PostRegistationOptions = ["thank", "promo", "link"] as const;
export type PostRegistationOption = (typeof PostRegistationOptions)[number];

export const PolicyEntities = [
  { path: "artist", name: "Artist" },
  { path: "management", name: "Management" },
  { path: "recordLabel", name: "Record Label" },
  { path: "marketing", name: "Marketing" },
  { path: "other", name: "Other" },
] as const;
export type PolicyEntity = (typeof PolicyEntities)[number]["path"];

export interface PrivacyPolicy {
  url: string;
  entity: PolicyEntity;
  name: string;
  isSeparate: boolean;
  source: string;
}

export const PostShowEmailTypes = [
  "disabled",
  "default",
  "custom",
  "html",
] as const;
export type PostShowEmailType = (typeof PostShowEmailTypes)[number];

export const PostShowEmailCTATypes = ["survey", "custom", "disabled"] as const;
export type PostShowEmailCTAType = (typeof PostShowEmailCTATypes)[number];

export const SweepstakesRulesTypes = [
  "default",
  "link",
  "html",
  "custom",
] as const;
export type SweepstakesRulesType = (typeof SweepstakesRulesTypes)[number];

export interface BuilderFields<T = Timestamp> {
  type: {
    content: EventTypes;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  colors: {
    content?: SelectedTemplateColors[];
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  image: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  concert: {
    content?: Concert;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  displayedName: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  startTime: {
    content?: T;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    saveTransform: (d: any) => any;
    loadTransform: (d: any, c: BuilderConfig) => any;
  };
  checkInVote: {
    content?: CheckInVoteOption;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  voteType: {
    content?: VoteTypeOption;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  preRegistrationHeadline: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    label: string;
    category: string;
  };
  preRegistrationBody: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  preRegistrationButtonLabel: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  preRegistrationSubtitle: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  voteOptions: {
    content?: TranslatableContent[];
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  songOptions: {
    content?: Song[];
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  sweepstakes: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  sweepstakesPrize: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  sweepstakesPrizeTerms: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  sweepstakesPrizeCustom: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  postRegistration: {
    content?: PostRegistationOption;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  postRegistrationHeadline: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  postRegistrationBody: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  postRegistrationButtonLabel: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  postRegistrationAlbum: {
    content?: Release;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  postRegistrationCustomAlbum: {
    content?: Release;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  postRegistrationLinkLabel: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
  };
  postRegistrationLinkUrl: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  merchandise: {
    content?: Merchandise[];
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  hideMerchandise: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  merchandiseUrl: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  privacyPolicyDeclined: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  privacyPolicyConfirm: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  privacyPolicies: {
    content: PrivacyPolicy[];
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
  };
  registerTitle: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
    admin?: boolean;
  };
  registerSubtitle: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
    admin?: boolean;
  };
  registerCtaLabel: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
    admin?: boolean;
  };
  socialLinkSpotify: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkInstagram: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkTiktok: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkTwitter: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkYoutube: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkFacebook: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkShazam: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkPandora: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  socialLinkTidal: {
    content?: string;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  additionalLanguages: {
    content?: LanguageCode[];
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  postShowEmailType: {
    content?: PostShowEmailType;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  postShowEmailCTAType: {
    content?: PostShowEmailCTAType;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  postShowEmailHeadline: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings?: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailSubject: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings?: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailBody: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings?: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailCta: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailCtaLabel: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings?: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailCustomHtml: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailMerchEnabled: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  postShowEmailMerchHeadline: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings?: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailMerchCtaLabel: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings?: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  postShowEmailMerchDisableCta: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  postShowEmailReleaseEnabled: {
    content?: boolean;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: false;
  };
  postShowEmailReleaseHeadline: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    getWarnings?: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    category: string;
    label: string;
  };
  // Advanced Settings
  useCustomTermsAndPrivacyPolicy: {
    content?: boolean;
    isTranslateable: false;
    isDirty: boolean;
  };
  customTermsAndPrivacyPolicy: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  separateLineTermsAndPrivacyPolicy: {
    content?: TranslatableContent;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  useCustomSMSOptIn: {
    content?: boolean;
    isTranslateable: false;
    isDirty: boolean;
  };
  customSMSOptIn: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  makePhoneNumberFieldOptional: {
    content?: boolean;
    isTranslateable: false;
    isDirty: boolean;
  };
  makeTextMessageOptInOptional: {
    content?: boolean;
    isTranslateable: false;
    isDirty: boolean;
  };
  useMultiDay: {
    content?: boolean;
    isTranslateable: false;
    isDirty: boolean;
  };
  multiDayDate: {
    content?: T;
    isTranslateable: false;
    isDirty: boolean;
    saveTransform: (d: any) => any;
    loadTransform: (d: any, c: BuilderConfig) => any;
  };
  useCustomMerchTabName: {
    content?: boolean;
    isTranslateable: false;
    isDirty: boolean;
  };
  customMerchTabName: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  useCustomMainTabName: {
    content?: boolean;
    isTranslateable: false;
    isDirty: boolean;
  };
  customMainTabName: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  masterCampaignId: {
    content?: string;
    isTranslateable: false;
    isDirty: boolean;
  };
  sweepstakesRulesType: {
    content?: SweepstakesRulesType;
    isTranslateable: false;
    isDirty: boolean;
  };
  sweepstakesRulesLink: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  sweepstakesRulesCustomUrl: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  sweepstakesRulesHtml: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
  footer: {
    content?: TranslatableContent;
    getValidation: (b: BuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    label: string;
    category: string;
    admin?: boolean;
  };
}

/**
 * Only the "content" portion of Builder fields.
 */
export type BuilderFieldsContent<T = Timestamp> = {
  [K in keyof BuilderFields<T>]: {
    content?: BuilderFields<T>[K]["content"];
  };
};

export interface BuilderConfigRoot {
  artistGroupId: string;
  artistName: string;
  maxArtistId: number;
  spotifyArtistId: string;

  // Last version ID that was published
  publishedId?: string;

  // Generated automatically when creating a new event.
  draftId?: string;

  // Additional groups sharing this event
  additionalGroups?: string[];
}

export const BuilderConfigStatuses = [
  "created",
  "draft",
  "publish_requested",
  "published",
] as const;
export type BuilderConfigStatus = (typeof BuilderConfigStatuses)[number];

export interface VirtualOptIns {
  terms?: string[];
  sms?: string[];
}

export type CustomRegister = {
  [index in LanguageCode]?: Register;
} & {
  en: Register;
};

export interface BuilderConfigDocument<T = Timestamp>
  extends Omit<BuilderConfigRoot, "publishedId" | "draftId"> {
  //colors?: Colors;
  //coordinates?: Coordinates;
  privacyPolicyUrl?: string;
  privacyPolicyUrlOrigin?: string;

  merchandiseUrl: string;
  analysisId: string;

  concerts: Concert[];
  images: Image[];
  //merch: Merchandise[];
  releases: Release[];

  status: BuilderConfigStatus;

  fields: { [K in keyof BuilderFieldsContent<T>]?: BuilderFieldsContent<T>[K] };

  customRegister?: CustomRegister;

  customPostShowEmail?: {
    from?: string;
  };

  /** This property is for opt-ins that remain hidden on the registration form but are enabled if the `terms` or `sms` opt-ins are checked */
  virtualOptIns?: VirtualOptIns;
}

export type BuilderConfig<T = DateTime> = Partial<
  Omit<BuilderConfigDocument<T>, "fields">
> & { fields: BuilderFields<T> };

/**
 * A special version of CreatorDataVersion that sets all keys in fields to
 * optional.
 */
export interface PartialBuilderConfigDocument<T = Timestamp>
  extends Omit<BuilderConfigDocument<T>, "fields"> {
  fields: Partial<BuilderFieldsContent<T>>;
}

export type Fields<T = Timestamp> = keyof BuilderFields<T>;

interface GenerateEventVersionProps<T extends Timestamp = Timestamp> {
  language?: keyof TranslatableContent;
  demoConfig?: EventConfig<T>;
  props?: {
    override: {
      type?: EventTypes;
      colors?: ColorsOption;
      disableDefaultCopy?: boolean;
    };
  };
  Timestamp: { fromDate: (d: Date) => T };
}

/**
 * Check to see if the provided object is a Luxon DateTime instance.
 */
const isDateTime = (maybeDateTime: unknown): maybeDateTime is DateTime => {
  const d = Object.getOwnPropertyDescriptor(maybeDateTime, "isLuxonDateTime");
  return d?.value === true;
};

/**
 * Generate a SETLive fan config document.
 */
export const generateEventVersion = <T extends Timestamp = Timestamp>(
  config: Partial<BuilderConfigDocument<T | DateTime>>,
  {
    language = "en",
    demoConfig,
    props,
    Timestamp,
  }: GenerateEventVersionProps<T>,
): EventConfig<T> => {
  const postRegText = generatePostRegText(config);
  const { preRegText } = generatePreRegText(config);

  //
  // TODO: Make translatable
  //
  const { sms, terms, separateTerms } = generatePrivacyText(
    config,
    language as PrivacyLanguages,
  );

  const getTimestamp = (dateThingy: T | DateTime): T => {
    if (isDateTime(dateThingy)) {
      return Timestamp.fromDate(dateThingy.toJSDate());
    }
    return dateThingy;
  };

  const disableDefaultCopy = !!props?.override.disableDefaultCopy;

  const image =
    config?.fields?.image?.content ||
    config?.images?.[0]?.src ||
    demoConfig?.header.image;

  const { imageColors, imageIndex } = getColorConfig(config);

  const date = config.fields?.startTime?.content
    ? getTimestamp(config.fields.startTime.content)
    : demoConfig?.header.date;

  const type =
    props?.override?.type !== undefined
      ? props?.override?.type
      : config.fields?.type?.content || 0;

  const colors =
    props?.override?.colors ||
    config.fields?.colors?.content?.[imageIndex]?.[type] ||
    imageColors?.[type]?.[0];

  if (
    !config.fields ||
    config.fields?.type?.content === undefined ||
    !image ||
    !date ||
    !colors
  ) {
    throw new Error("TODO: Handle this");
  }

  if (disableDefaultCopy) {
    preRegText.headline = "";
    preRegText.subtitle = "";
    preRegText.body = "";
    postRegText.headline = "";
    postRegText.body = "";
  }

  const optionalSms =
    config.customRegister?.[language]?.optionalSms ||
    !!config.fields.makeTextMessageOptInOptional?.content;
  const optionalPhone =
    config.customRegister?.[language]?.optionalPhone ||
    !!config.fields.makePhoneNumberFieldOptional?.content;

  const register = {
    ...demoConfig?.register,
    ...config.customRegister?.[language],
    title: config.fields.registerTitle?.content?.[language],
    subtitle: config.fields.registerSubtitle?.content?.[language],
    ctalabel:
      config.fields.registerCtaLabel?.content?.[language] ||
      (config?.fields?.checkInVote?.content === "vote" ? "Vote" : "Check In"),
    sms,
    terms,
    ...(!config.fields.useCustomTermsAndPrivacyPolicy?.content
      ? {
          ...(!!separateTerms?.length && {
            customTerms: separateTerms[0],
          }),
          ...(separateTerms?.length &&
            separateTerms.length > 1 && {
              customAgreement: separateTerms[1],
            }),
        }
      : {
          terms: config.fields.customTermsAndPrivacyPolicy?.content?.[language],
          customTerms:
            config.fields.separateLineTermsAndPrivacyPolicy?.content?.[
              language
            ],
        }),
    ...(config.fields.useCustomSMSOptIn?.content && {
      sms: config.fields.customSMSOptIn?.content?.[language],
    }),
    hasSweepstakes: config.fields.sweepstakes?.content,
    optionalSms,
    optionalPhone,
  };

  if (config.fields.sweepstakes?.content) {
    if (config.fields.sweepstakesRulesType?.content === "html") {
      register.customSweepstakesRules =
        config.fields.sweepstakesRulesHtml?.content?.[language];
    }
    if (config.fields.sweepstakesRulesType?.content === "link") {
      register.customSweepstakesRules =
        (process.env.REACT_APP_RULES_URL || "https://rules.set.live") +
        `/${config.fields.sweepstakesRulesLink?.content?.[language]}`;
    }
    if (config.fields.sweepstakesRulesType?.content === "custom") {
      register.customSweepstakesRules =
        config.fields.sweepstakesRulesCustomUrl?.content?.[language];
    }
  }

  const fanConfig: EventConfig<T> = {
    ...demoConfig,
    type,
    header: {
      ...demoConfig?.header,
      artistName:
        config?.fields?.displayedName?.content || config.artistName || "",
      date,
      image,
      venue: config.fields.concert?.content?.venue,
    },
    cards: [
      {
        cta: "register",
        visible: {
          states: [UserEventStates.draft],
        },
        headline:
          config.fields.preRegistrationHeadline?.content?.[language] ||
          preRegText.headline,
        ...(config.fields.checkInVote?.content === "vote"
          ? {
              // Voting
              tab: "Vote",
              subtitle:
                config.fields.preRegistrationSubtitle?.content?.[language] ||
                preRegText.subtitle,
              ...(config.fields.voteType?.content === "song"
                ? {
                    // Songs
                    songs: config.fields.songOptions?.content
                      ?.filter((song) => !!song.name)
                      ?.map((s) => ({
                        id: s.name + s.album || "",
                        title: s.name || "",
                        album: s.album || "",
                      })),
                  }
                : {
                    // Something else
                    songs: config.fields.voteOptions?.content
                      ?.filter((option) => !!option?.[language])
                      ?.map((s) => ({
                        id: s?.[language] || "",
                        title: s?.[language] || "",
                      })),
                  }),
            }
          : {
              // Check In
              tab: "Check In",
              ctalabel: "Check In",
              body:
                config.fields.preRegistrationBody?.content?.[language] ||
                preRegText.body,
              ...(config.fields.preRegistrationButtonLabel?.content?.[
                language
              ] && {
                ctalabel:
                  config.fields.preRegistrationButtonLabel?.content?.[language],
              }),
            }),
      },
    ],
    colors: colors,
    register,
    social: {
      title: `${config.fields.postRegistrationButtonLabel?.content?.[language]} ${config.fields.postRegistrationAlbum?.content?.name} on your favorite streaming service.`, //TODO: this should come from somewhere
      // subtitle: "available on:", this field not in use
      spotify: config.fields.postRegistrationAlbum?.content?.spotify,
      apple: config.fields.postRegistrationAlbum?.content?.appleMusic,
      amazon: config.fields.postRegistrationAlbum?.content?.amazon,
      deezer: config.fields.postRegistrationAlbum?.content?.deezer,
      youtube: config.fields.postRegistrationAlbum?.content?.youtubeMusic,
      pandora: config.fields.postRegistrationAlbum?.content?.pandora,
      tidal: config.fields.postRegistrationAlbum?.content?.tidal,
    },
    socialLinks: {
      facebook: config.fields.socialLinkFacebook?.content,
      instagram: config.fields.socialLinkInstagram?.content,
      tiktok: config.fields.socialLinkTiktok?.content,
      spotify: config.fields.socialLinkSpotify?.content,
      youtube: config.fields.socialLinkYoutube?.content,
      twitter: config.fields.socialLinkTwitter?.content,
      shazam: config.fields.socialLinkShazam?.content,
    },
    ...(config.fields.footer?.content?.en && {
      footer: config.fields.footer?.content?.en,
    }),
  };

  if (fanConfig.cards && config.fields.useCustomMainTabName?.content === true) {
    fanConfig.cards[0].tab =
      config.fields.customMainTabName?.content?.[language];
  }

  // Post registration view
  if (fanConfig.cards) {
    fanConfig.cards.push({
      tab: fanConfig.cards[0].tab,
      ...postRegText,
      headline:
        config.fields.postRegistrationHeadline?.content?.[language] ||
        postRegText.headline,
      body:
        config.fields.postRegistrationBody?.content?.[language] ||
        postRegText.body,
      // Promote a Release
      ...(config.fields.postRegistration?.content === "promo" && {
        image: config?.fields?.postRegistrationAlbum?.content?.image,
        ctalabel:
          config.fields.postRegistrationButtonLabel?.content?.[language] ||
          postRegText.ctalabel,
        cta: "social",
      }),
      // Custom Link
      ...(config.fields.postRegistration?.content === "link" && {
        cta: config.fields.postRegistrationLinkUrl?.content || postRegText.cta,
        ctalabel:
          config.fields.postRegistrationLinkLabel?.content?.[language] ||
          postRegText.ctalabel,
      }),
      visible: {
        states: [UserEventStates.draft],
        hide: true,
      },
    });
  }

  // Merch View
  const merchTabName = !config.fields.useCustomMerchTabName?.content
    ? "Merch"
    : config.fields.customMerchTabName?.content?.[language];
  if (
    !config.fields.hideMerchandise?.content &&
    !!config.fields.merchandise?.content?.length &&
    fanConfig.cards
  ) {
    fanConfig.cards.push({
      tab: merchTabName,
      ...(fanConfig.type === 0 && {
        headline: merchTabName,
        icon: "shirt",
      }),
      merch: config.fields.merchandise?.content?.reduce<Merch[]>(
        (acc: Merch[], m: Merchandise) => {
          if (m.isSelected) {
            acc.push({
              ...m,
              photo: m.img,
              name: m.text,
            });
          }
          return acc;
        },
        [],
      ),
    });
  }

  // If swiper, add icons to pre & post registration cards
  if (fanConfig.type === 0 && fanConfig.cards) {
    fanConfig.cards[0].icon =
      config.fields.checkInVote?.content === "vote" ? "checklist" : "user";
    fanConfig.cards[1].icon = "checkmark";
  }

  const country = getAddressComponent(
    "country",
    config.fields.concert?.content?.geocode?.address_components,
  );
  if (!!country) {
    fanConfig.meta = {
      country: country as CountryCode,
    };
  }

  // Custom post show email 'from'
  if (config.customPostShowEmail?.from) {
    fanConfig.customPostShowEmail = { ...config.customPostShowEmail };
  }

  return removeUndefined(fanConfig);
};
